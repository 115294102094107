import React, { useState } from 'react';
import { get, post, put, remove } from '../../Services/http';
import 'react-table-v6/react-table.css'

const ExcelImportPanel = () => {

    const buttonstyle = {
        width: '100px',
        marginRight: '20px',
    };
    const containerStyle = {
        marginTop: '25px',
    };
    return (
        <form name="form1" method="post" enctype="multipart/form-data" action="/api/excelimport/import">
            <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'arial' }}>
                <h1>Excel Import</h1>
                <div>
                    <input type="file" id="importfile" name="importFile"></input>
                    <div style={containerStyle}>
                        <input style={buttonstyle} type="submit" value="Importera" />
                    </div>
                </div>
            </div>
        </form>);
}

export default ExcelImportPanel;