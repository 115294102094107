import React, { Component } from 'react';
import QuickOrder from '../Components/QuickOrder';
import { add as addToCart, addMultiple as addMultipleToCart } from '../Actions/Cart.action';
import { connect } from 'react-redux';

class QuickOrderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props };
    }

    render() {
        return (<QuickOrder isLoading={this.props.isLoading} addToCart={this.props.addToCart} addMultipleToCart={this.props.addMultipleToCart} />);
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.quickOrder.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addToCart: (article) => dispatch(addToCart(article)),
        addMultipleToCart: (products, id, hash) => dispatch(addMultipleToCart(products, id, hash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickOrderContainer);