import React from "react"
import { translate } from "../Services/translation"

const NO_LINK_VALUE = "default"

export default function CompanyLinkSelector(props) {
    const { links, productPage } = props

    const [currentLink, setCurrentLink] = React.useState(NO_LINK_VALUE)

    const onSelect = e => {
        setCurrentLink(e.target.value)
    }

    const hasLink = () => currentLink && currentLink != NO_LINK_VALUE

    return <div className={productPage ? "row-inner" : null}>
        <div className={productPage ? "small-12 medium-6 columns" : null}>
            <select className="form__input" onChange={onSelect} value={currentLink}>
                {links.map((link, i) => 
                    <option key={link.name+link.url} value={i != 0 ? link.url : NO_LINK_VALUE} disabled={i == 0}>
                        {link.name}
                    </option>
                )}
            </select>
        </div>
        <div className={productPage ? " small-12 medium-6 columns" : null}>
            <a className="button" target="_blank" href={hasLink() ? currentLink : null} disabled={!hasLink()}>{translate("companylink.show-product-at-retailer")}</a>
        </div>
    </div>
}