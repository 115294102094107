import React from 'react';
import ReactDOM from 'react-dom';
import ExcelImportPanel from './ExcelImportPanel';
import NavControllerContainer from './NavController';

if (document.getElementById('navcontroller')) {

    ReactDOM.render(
        <NavControllerContainer />,
        document.getElementById('navcontroller')
    );
}
if (document.getElementById('excelimportpanel')) {

    ReactDOM.render(
        <ExcelImportPanel />,
        document.getElementById('excelimportpanel')
    );
}