import React, { Component } from 'react';
import { connect } from 'react-redux';
import FacetedSearch from '../Components/FacetedSearch';
import { query, searchFacetChange } from '../Actions/FacetedSearch.action';
import { toggleGreyOut, setActiveFacetTab, outsideClicked } from '../Actions/UIState.action';

class FacetedSearchContainer extends Component {
    render() {
        return <FacetedSearch {...this.props} />;
    }
}
const mapStateToProps = ({ facetedSearch: { facetFilters, navigationTheme }, UIState }) => {
    return {
        facetFilters,
        navigationTheme,
        isGreyedOut: UIState.isGreyedOut,
        activeFacetTab: UIState.activeFacetTab
    }
}

const mapDispatchToProps = dispatch => {
    return {
        query: (q, replaceResult) => dispatch(query(q, replaceResult)),
        searchFacetChange: (group, item) => dispatch(searchFacetChange(group, item)),
        toggleGreyOut: () => dispatch(toggleGreyOut()),
        setActiveFacetTab: (activeTab) => dispatch(setActiveFacetTab(activeTab)),
        outsideClicked: () => dispatch(outsideClicked())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacetedSearchContainer);