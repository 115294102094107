import React from "react";
import { PDFDocument, rgb, StandardFonts} from 'pdf-lib';
import { translate } from '../Services/translation';
import { detect } from "detect-browser";

const ProductSheet = ({values}) => {

    const browser = detect();
    const getProductImage =  async (page, pdfDoc) => {

        var imgUrl = values.urlProductImage;

        if(imgUrl !== "")
        {
            var imageBytes;
            var image;
            const maxWidth = 187;
            const maxHeight = 411;

            if(imgUrl.toLowerCase().endsWith('.png')) {
                imageBytes = await fetch(imgUrl).then((res) => res.arrayBuffer());
                image = await pdfDoc.embedPng(imageBytes);
            }
            else if (imgUrl.toLowerCase().endsWith('.jpg') || imgUrl.toLowerCase().endsWith('.jpeg')) {
                imageBytes = await fetch(imgUrl).then((res) => res.arrayBuffer());
                image = await pdfDoc.embedJpg(imageBytes);
            }
            else {
                image = '../../images/fallback_pdf_image.jpg'; 
            }

            const scaledImage = image.scaleToFit(187, 411);

            page.drawImage(image, {
            x: 330  + (maxWidth / 2) - (scaledImage.width / 2),
            y: 125,
            height: scaledImage.height,
            width: scaledImage.width
            });
        }
    }

    const getTableRows = (page, helveticaFont) => {
        const tableRowValues = [
            values.brand,
            values.articleNumber,
            values.EAN,
            values.quantity,
            values.netWeight,
            values.STLength,
            values.STWidth,
            values.STHeight
        ];

        var xCord = 137;
        var yCord = 319;

        tableRowValues.forEach(row => {
            page.drawText(row, {
                x: xCord,
                y: yCord,
                size: 9,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            yCord -= 25;
        });
    }

    const getFontSize = (text) => {
        if(text.length >= 550){
            return 8;
        }
        return 10;
    }
    
    const getLineHeight = (text) => {
        if(text.length >= 800){
            return 9;
        }
        if(text.length >= 550){
            return 10;
        }
        return 15;
    }

    const getText = (text, sText) => {
        if(text.length >= 950){
            return sText;
        }
        return text;
    }
    
    const getInstructionsLineHeight = (text) => {
        if(text.length >= 850){
            return 10;
        }
        if(text.length >= 550){
            return 15;
        }
        return 20;
    }
    async function createProductSheet(){
        
        const url = values.urlSheet;
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const validChars = helveticaFont.getCharacterSet();

        const pages = pdfDoc.getPages()
        const page = pages[0]

        const { width, height } = page.getSize()

        var title = "";

        if(values.name != null || values.name != undefined){
            title = values.name.toUpperCase();
        }
        const titleSize = ((title.length <= 42) ? 20 : 15);
        const titleWidth = helveticaFont.widthOfTextAtSize(title, titleSize);

        const stripInvalidCharacters = (text) => {
            let resultText = "";
            for (let i = 0; i < text.length; i++) {
                if (validChars.indexOf(text.charCodeAt(i)) != -1 || text.charAt(i) == " " || text.charCodeAt(i) == 10) {
                    resultText += text.charAt(i);
                }
            }
            return resultText;
        }

        const descSize = 10;

        //Library used custom rgb values, this is to calculate to normal css rgb
        const rColor = 27/255;
        const gColor = 20/255;
        const bColor = 100/255;

        page.drawText(title,{
            x: width / 2 - titleWidth / 2,
            y: 750,
            size: titleSize,
            font: helveticaFont,
            color: rgb(rColor, gColor, bColor),
        })

        var description = stripInvalidCharacters(getText(values.description, values.shortDescription));
        var instructions = stripInvalidCharacters(getText(values.instructions, values.shortInstructions));

        page.drawText(description, {
            x: 65,
            y: 725,
            maxWidth: width - 100,
            lineHeight: getLineHeight(description),
            size: getFontSize(description),
            lines: 13,
            font: helveticaFont,
            color: rgb(0, 0, 0)
        })

        page.drawText(instructions,{
            x: 65,
            y: 550,
            size: getFontSize(instructions),
            font: helveticaFont,
            color: rgb(0, 0, 0),
            maxWidth: width - 350,
            lineHeight: getInstructionsLineHeight(instructions)
        });

        getTableRows(page, helveticaFont);

        await getProductImage(page, pdfDoc);

        const pdfBytes = await pdfDoc.save();

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blob);

        switch (browser && browser.name){
            case 'firefox':
                a.href = fileURL
                a.target = '_blank';
                a.click();
                break;
            default:
                const pdfWindow = window.open();
                pdfWindow.create
                pdfWindow.location.href = fileURL;
        
                a.href = fileURL;
                a.download = values.name;
                a.click();
                break;
                
        }
    }

    return(
        <label className="description-label">
            <i className="fal fa-link"></i>
            <a className="file-link"onClick={createProductSheet}>{translate('product.productsheet')}</a>
        </label>
    )
}


export default ProductSheet;