import React, { Component } from 'react';
import QuickSearchResult from './QuickSearchResult';
import { translate } from '../Services/translation';

class QuickSearch extends Component {
    focusOnInput() {
        setTimeout(() => {
            this.searchInput && this.searchInput.focus();
        }, 0);
    }
    
    render () {
        const { query, result, showResult, showFullForm, onSearch, onBlur, onKeyDown, toggleShowFullForm, selectedItem, searchUrl, onButtonClick } = this.props;
        return (
            <div className="quick-search" role="search">
                <a className="quick-search__link--block" onClick={e => {toggleShowFullForm(); this.focusOnInput()}}>
                    <i className="fal fa-search"></i>
                    <span className="quick-search__title">{translate('general.search')}</span>
                </a>
                <div className={`quick-search__form ${showFullForm || showResult ? 'quick-search__form--force-show' : ''}`} role="search">
                    <i className="quick-search__icon" onClick={e => toggleShowFullForm()}></i>
                    <input className="quick-search__input" aria-label="search" type="search" placeholder={translate('general.search')}
                        autoComplete="off" value={query} onChange={event => onSearch(event.target.value)}
                        onKeyDown={event => onKeyDown(event, {searchUrl})} ref={(input) => { this.searchInput = input; }} 
                        onBlur={() => onBlur() } />
                    <button className="quick-search__submit-button" aria-label="submit search" type="submit" onClick={e => onButtonClick({searchUrl: searchUrl, searchInput: this.searchInput.value})}>
                        <i className="quick-search__submit fal fa-search"></i>
                        <span className="quick-search__submit-title">{translate('general.search')}</span>
                    </button>
                </div>
                {showResult && <QuickSearchResult result={result} selectedItem={selectedItem} searchUrl={searchUrl}/>}
            </div>
        )
    }
}

export default QuickSearch;