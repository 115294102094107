window.__litium = window.__litium || {};
window.__litium.constants = window.__litium.constants || {};
export default window.__litium.constants || window.__litium || window || {};
export const ViewMode = {
    List: 'list',
    Edit: 'edit',
    Detail: 'detail',
  };
  
  export const ADDRESS_RECEIVE = 'ADDRESS_RECEIVE';
  export const ADDRESS_ERROR = 'ADDRESS_ERROR';
  export const ADDRESS_CHANGE_MODE = 'ADDRESS_CHANGE_MODE';
  
  export const NAVIGATION_LOAD_ERROR = 'NAVIGATION_LOAD_ERROR';
  export const NAVIGATION_RECEIVE = 'NAVIGATION_RECEIVE';
  
  export const PERSON_RECEIVE = 'PERSON_RECEIVE';
  export const PERSON_ERROR = 'PERSON_ERROR';
  export const PERSON_CHANGE_MODE = 'PERSON_CHANGE_MODE';
  
  export const ORDER_RECEIVE = 'ORDER_RECEIVE';
  export const ORDER_ERROR = 'ORDER_ERROR';
  export const ORDER_CHANGE_MODE = 'ORDER_CHANGE_MODE';
  export const ORDER_CHANGE_CURRENTPAGE = 'ORDER_CHANGE_CURRENTPAGE';
  export const ORDER_SET_ORDER = 'ORDER_SET_ORDER';
  
  export const MY_PAGES_DETAILS = 'MY_PAGES_DETAILS';
  export const MY_PAGES_ORDER_HISTORY = 'MY_PAGES_ORDER_HISTORY';
  export const MY_PAGES_PERSONLIST = 'MY_PAGES_PERSONLIST';
  export const MY_PAGES_SET_ACTIVE_TAB = 'MY_PAGES_SET_ACTIVE_TAB';
  export const MY_PAGES_SHOW_DROPDOWN = 'MY_PAGES_SHOW_DROPDOWN';
  export const MY_PAGES_QUICKORDER = 'MY_PAGES_QUICKORDER';
  export const MY_PAGES_ADDRESS = "MY_PAGES_ADDRESS";
  export const MY_PAGES_START_PAGE = "MY_PAGES_START_PAGE";

export const PaginationOptions = {
    PageSize: 5, // Number of items per page
    DisplayedEntries: 4, // Specifies how many links to show excluding possible EdgeEntries
    EdgeEntries: 2, // Specifies how many links from beginning and end to show ex. 1 2 3 ... 10 20 30 ... 97 98 99 where "1 2 3" and "97 98 99" are edge entries
  };