import React, { useState } from 'react';
import { get, post, put, remove } from '../../Services/http';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';

export default function NavControllerContainer() {

    const [rowInfo, setRowInfo] = useState("");
    const [data, setData] = useState([]);
    const [innerData, setInnerData] = useState([]);

    const SendOrdersToNav = () => {
        post('/api/integration/sendorderstonav')
        console.log("Orders sent to NAV");
    }

    const StartIntegration = () => {
        post('/api/integration/runintegration')
        console.log("Integration ran");
    }

    const TestErrorMail = () => {
        post('/api/integration/testerroremail')
        console.log("testerroremail ran");
    }

    const LoadQueue = () => {
        post('/api/integration/loadqueue')
            .then(response => response.json())
            .then(result => {
                setRowInfo("");
                setInnerData([]);
                var timeResult = result.filter(x => x.timeStamp != null);
                timeResult.map((item) => {
                    item.timeStamp = new Date(item.timeStamp).toString();
                }
                )
                setData(result);
            })
    }

    const LoadItem = (id) => {
        post('/api/integration/loaditem', id)
            .then(response => response.json())
            .then(result => {
                setInnerData(result.items);
                setRowInfo(result.items[0].info);
            })
    }

    const buttonStyle = {
        color: "white",
        cursor: "pointer",
        border: "solid 1px",
        borderColor: "transparent",
        borderRadius: "6px",
        textAlign: "center",
        padding: "12px",
        fontSize: "13px",
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        marginBottom: '20px',
        marginTop: '10px'
    }

    const greenBtn = {
        background: "#5c9870"
    }
    const blueBtn = {
        background: "#46aaf8"
    }
    const yellowBtn = {
        background: "#ffb000"
    }
    const orangeBtn = {
        background: "#ff781f"
    }

    const rowStyle = {
        border: 'solid #e8e8e8 1px',
        marginTop: '17px',
        borderRadius: '5px',
        padding: '15px'
    }

    const columns = [{
        Header: 'Id',
        accessor: 'id'
    }, {
        Header: 'Transaction Id',
        accessor: 'transactionId'
    }, {
        Header: 'Type',
        accessor: 'type'
    }, {
        Header: 'Time stamp',
        accessor: 'timeStamp',
    },
    {
        Header: 'Status',
        accessor: 'status'
    }]

    const innerColumns = [{
        Header: 'Id',
        accessor: 'id'
    }, {
        Header: 'Integration status id',
        accessor: 'integrationStatusId'
    }, {
        Header: 'Status',
        accessor: 'status',
    }]

    return (
        <div style={{ fontFamily: 'Helvetica Neue,Lato,sans-serif', padding: '3px 20px' }}>
            <div style={
                {
                    display: "flex",
                }
            }>
                <div onClick={() => StartIntegration()} style={{...buttonStyle, ...greenBtn}}>Run task</div>
                <div onClick={() => LoadQueue()} style={{...buttonStyle, ...blueBtn}}>Load queue</div>
                <div onClick={() => SendOrdersToNav()} style={{...buttonStyle, ...yellowBtn}}>Send orders to NAV</div>
                <div onClick={() => TestErrorMail()} style={{...buttonStyle, ...orangeBtn}}>Test error mail</div>

            </div>
            <ReactTable
                data={data}
                columns={columns}
                filterable={true}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            LoadItem(rowInfo.original.id);
                        }
                    }
                }}
                style={{ cursor: 'pointer' }}
            />
            {innerData.length > 0 &&
                <>
                    <h3>Details</h3>
                    <ReactTable
                        data={innerData}
                        columns={innerColumns}
                        defaultPageSize={innerData.length}
                        showPagination={true}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        showPageSizeOptions={false}
                        style={{ marginTop: '20px' }} />
                </>
            }
            {rowInfo != "" &&
                <>
                    <h4 style={{ marginBottom: '10px' }}>Info</h4>
                    <div className="selected-row" style={rowStyle}>
                        <div>
                            <div>{rowInfo}</div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}