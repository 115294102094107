import { combineReducers } from 'redux';
import { cart } from './Reducers/Cart.reducer';
import { quickSearch } from './Reducers/QuickSearch.reducer';
import { navigation } from './Reducers/Navigation.reducer';
import { facetedSearch } from "./Reducers/FacetedSearch.reducer";
import { UIState } from "./Reducers/UIState.reducer";
import { myPage } from './Reducers/MyPage.reducer';
import { checkout } from "./Reducers/Checkout.reducer";
import { lightboxImages } from './Reducers/LightboxImages.reducer';
import { notification } from './Reducers/Notification.reducer';
import { quickOrder } from './Reducers/QuickOrder.reducer';
import { order } from './Reducers/Order.reducer';
import { person } from './Reducers/Person.reducer';
import { address } from './Reducers/Address.reducer';

const staticReducers = {
    cart,
    checkout,
    quickSearch,
    navigation,
    myPage,
    order,
    facetedSearch,
    lightboxImages,
    notification,
    UIState,
    quickOrder,
    person,
    address,
  };

const app = combineReducers(staticReducers);

export const createReducer = (asyncReducers) => {
    return combineReducers({
      ...staticReducers,
      ...asyncReducers,
    });
  };

export default app;