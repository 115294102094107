export const GREY_OUT_CLICKED = 'GREY_OUT_CLICKED';
export const GREY_OUT_TOGGLE = 'GREY_OUT_TOGGLE';
export const COLLAPSE_ALL = 'COLLAPSE_ALL';
export const SET_ACTIVE_FACET_TAB = 'SET_ACTIVE_FACET_TAB';

export const toggleGreyOut = () => (dispatch) =>
    dispatch({
        type: GREY_OUT_TOGGLE,
    });

export const collapseAll = () => (dispatch) =>
    dispatch({
        type: COLLAPSE_ALL,
    });

export const setActiveFacetTab = (facetTab) => (dispatch) =>
    dispatch({
        type: SET_ACTIVE_FACET_TAB,
        facetTab: facetTab,
    });

export const outsideClicked = () => (dispatch) =>
    dispatch({
        type: GREY_OUT_CLICKED,
    });

