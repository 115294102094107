import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { applyMiddleware, bindActionCreators, createStore } from 'redux';
import { historyMiddleware } from './Middlewares/History.middleware';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import app, { createReducer } from './reducers'
import { add as addToCart, reorder} from './Actions/Cart.action';
import { show as showNotification } from './Actions/Notification.action'
import UIStateContainer from './Containers/UIState.container';
import MiniCartContainer from './Containers/MiniCart.container';
import QuickSearchContainer from './Containers/QuickSearch.container';
import NavigationContainer from './Containers/Navigation.container';
import QuickOrderContainer from './Containers/QuickOrder.container';
import ProductSheet from './Components/ProductSheet';
import FacetedSearchContainer from './Containers/FacetedSearch.container';
import FacetedSearchCompactContainer from './Containers/FacetedSearchCompact.container';
import NotificationContainer from './Containers/Notification.container';
import CompanyLinkSelector from './Components/CompanyLinkSelector';
import DynamicComponent from './Components/DynamicComponent';
import {
    setImages as setProductImageSlideShows,
    show as showProductImageSlideShow,
    setCurrentIndex as setProductImageSlideShowIndex
} from './Actions/LightboxImages.action';
import { translate } from './Services/translation';
import 'whatwg-fetch';
import './Components/CustomPanel/CustomPanels.js';
import "../Styles/site.scss"

window.__litium = window.__litium || {};
const preloadState = window.__litium.preloadState || {};
const store = createStore(app, preloadState, composeWithDevTools(applyMiddleware(thunk, historyMiddleware)));

store.asyncReducers = {};

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
store.injectReducer = (key, asyncReducer) => {
  if (!store.asyncReducers[key]) {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  }
};
// register redux's actions so we can invoke them from vanilla JS
// usage: window.__litium.reduxActions.addToCart(...);
window.__litium = {
    ...window.__litium,
    reduxActions: bindActionCreators({ addToCart, reorder, setProductImageSlideShows, showProductImageSlideShow, setProductImageSlideShowIndex, showNotification }, store.dispatch),
    events: {
        onAddToCartButtonClick: (sourceDomNode, articleNumber, quantityFieldId = null) => {
            const nodeIdToShowNotification = sourceDomNode.id = Date.now();
            let packagingQuantity = 1
            let maxQuantity = 0;
            let minQuantity = 0;
            let inputNumber = Number(document.getElementById(quantityFieldId).value);
            let minText = "", maxText = "";
            minText = translate('buybutton.mintext');
            maxText = translate('buybutton.maxtext');
            let maxExceeded = "";

            if (sourceDomNode.getAttribute("data-select-quantity")) {
                packagingQuantity = sourceDomNode.getAttribute("data-select-quantity");
            }
            if (sourceDomNode.getAttribute("data-max-quantity")) {
                maxQuantity = sourceDomNode.getAttribute("data-max-quantity");
            }
            if (sourceDomNode.getAttribute("data-min-quantity")) {
                minQuantity = sourceDomNode.getAttribute("data-min-quantity");
            }
            if (sourceDomNode.getAttribute("data-max-exceeded")) {
                maxExceeded = sourceDomNode.getAttribute("data-max-exceeded");
            }
            if (packagingQuantity == "0")
                packagingQuantity = 1;

            if (maxExceeded !== "True") {
                window.__litium.reduxActions.addToCart({
                    articleNumber,
                    quantity: quantityFieldId ? document.getElementById(quantityFieldId).value : 1,
                    inputValue: inputNumber ? inputNumber : 0,
                    nodeIdToShowNotification,
                    notificationMessage: translate('tooltip.addedtocart'),
                    hash: Date.now(),
                    packageQuantity: packagingQuantity,
                    minQuant: Number(minQuantity) ? Number(minQuantity) : 1,
                    maxQuant: Number(maxQuantity) ? Number(maxQuantity) : 999,
                    minText: minText,
                    maxText: maxText
                });
            }
        },
        onShowNotification: (sourceDomNode) => {
            const nodeIdToShowNotification = sourceDomNode.id = Date.now();
            window.__litium.reduxActions.showNotification(
                nodeIdToShowNotification,
                translate('tooltip.notinstock'),
                Date.now()
            );
        },

        onReorderClick: (sourceDomNode, orderid) => {
            const nodeIdToShowNotification = sourceDomNode.id;
            const notificationMessage = translate('tooltip.addedtocart');
            const hash = Date.now();
            if (orderid) {
                window.__litium.reduxActions.reorder({ orderid,
                    notificationMessage,
                    hash,
                 });
            }
        },
        onSearchResultDataChange: dom => {
            let temp = document.createElement('div');
            temp.innerHTML = dom;
            const currentResult = document.querySelector("#search-result");
            const newResult = temp.querySelector("#search-result");
            //move facetSearchCompact from currentResult to newResult
            newResult.replaceChild(currentResult.querySelector('#facetedSearchCompact'), newResult.querySelector('#facetedSearchCompact'));
            //replace currentResult with newResult
            currentResult.parentNode.replaceChild(newResult, currentResult);
        },
        onProductImageClick: ele => {
            const productImagesSelector = 'product-images';
            const productImageSelector = 'product-image';
            const closest = (el, className) => el ? (el.classList.contains(className) ? el : closest(el.parentNode, className)) : null;
            const parentNodes = ele.closest ? ele.closest(`.${productImagesSelector}`) : closest(ele, productImagesSelector);
            const images = Array.from(parentNodes.querySelectorAll(`.${productImageSelector}`))
                .map(img => ({ type: img.dataset.type, src: img.dataset.src, thumbnail: img.dataset.thumbnail }));
            const index = images.findIndex(_ele => ele.dataset.src === _ele.src);

            window.__litium.reduxActions.setProductImageSlideShows(images);
            window.__litium.reduxActions.showProductImageSlideShow(true);
            window.__litium.reduxActions.setProductImageSlideShowIndex(index);
        },
        onPackagingSelectChange: (sourceDomNode) => {
            let parentNode = sourceDomNode.closest(".info-button") ?
                sourceDomNode.closest(".info-button").querySelector(".product__buy-button") :
                sourceDomNode.closest(".product-detail__quantity-container").querySelector(".product-detail__buy-button");
            parentNode.setAttribute("data-select-quantity", sourceDomNode.value);
        },
    },
    bootstrapComponents: () => {
        // bootstrap React components, in case the HTML response we receive from the server
        // has React components. ReactDOM.render performs only an update on previous rendered
        // components and only mutate the DOM as necessary to reflect latest React element.
        bootstrapComponents();
    },
    cache: {}, // for storing cache data for current request
};
const bootstrapComponents = () => {
    if (document.getElementById('grey-out')) {
        ReactDOM.render(
            <Provider store={store}>
                <UIStateContainer />
            </Provider>,
            document.getElementById('grey-out')
        );
    }
    if (document.getElementById('miniCart')) {
        ReactDOM.render(
            <Provider store={store}>
                <MiniCartContainer />
            </Provider>,
            document.getElementById('miniCart')
        );
    }
    if (document.getElementById('quickSearch')) {
        ReactDOM.render(
            <Provider store={store}>
                <QuickSearchContainer />
            </Provider>,
            document.getElementById('quickSearch')
        );
    }
    if (document.getElementById('navbar')) {
        ReactDOM.render(
            <Provider store={store}>
                <NavigationContainer />
            </Provider>,
            document.getElementById('navbar')
        );
    }
    if (document.getElementById('facetedSearch')) {
        ReactDOM.render(
            <Provider store={store}>
                <FacetedSearchContainer />
            </Provider>,
            document.getElementById('facetedSearch')
        );
    }
    if (document.getElementById('facetedSearchCompact')) {
        ReactDOM.render(
            <Provider store={store}>
                <FacetedSearchCompactContainer />
            </Provider>,
            document.getElementById('facetedSearchCompact')
        );
    }
    if (document.getElementById('globalNotification')) {
        ReactDOM.render(
            <Provider store={store}>
                <NotificationContainer />
            </Provider>,
            document.getElementById('globalNotification')
        );
    }

    if (document.getElementById('myPagePersons')) {
        const PersonList = DynamicComponent({
            loader: () => import('./Containers/PersonList.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <PersonList />
            </Provider>,
            document.getElementById('myPagePersons')
        );
    }
    if (document.getElementById('myPageAddresses')) {
        const AddressList = DynamicComponent({
            loader: () => import('./Containers/AddressList.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <AddressList />
            </Provider>,
            document.getElementById('myPageAddresses')
        );
    }
    if (document.getElementById('checkout')) {
        const Checkout = DynamicComponent({
            loader: () => import('./Containers/Checkout.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <Checkout />
            </Provider>,
            document.getElementById('checkout')
        );
    }
    if (document.getElementById('lightBoxImages')) {
        const LightboxImages = DynamicComponent({
            loader: () => import('./Containers/LightboxImages.container')
        });

        ReactDOM.render(
            <Provider store={store}>
                <LightboxImages />
            </Provider>,
            document.getElementById('lightBoxImages')
        );
    }

    if (document.querySelectorAll('.slider').length > 0) {
        const Slider = DynamicComponent({
            loader: () => import('./Components/Slider'),
        });
        Array.from(document.querySelectorAll('.slider')).forEach((slider, index) => {
            const values = [...slider.querySelectorAll('.slider__block')].map(block => {
                return ({
                    image: block.dataset.image,
                    url: block.dataset.url,
                    text: block.dataset.text,
                })
            });
            if (values.length > 0) {
                ReactDOM.render(
                    <Slider values={values} />,
                    slider
                );
            }
        });
    }

    if (document.querySelectorAll('.campaignslider').length > 0) {
        const CampaignSlider = Loadable({
            loader: () => import('./Components/CampaignSlider'),
            loading: () => <div></div>,
        });
        Array.from(document.querySelectorAll('.campaignslider')).forEach((slider, index) => {
            const values = [...slider.querySelectorAll('.slider__block')].map(block => {
                return ({
                    backgroundimage: block.dataset.backgroundimage,
                    campaigntext: block.dataset.campaigntext,
                    campaignurl: block.dataset.campaignurl,
                    campaignstickertext: block.dataset.campaignstickertext,
                    largecolumns: block.dataset.largecolumns
                })
            });
            if (values.length > 0) {
                ReactDOM.render(
                    <CampaignSlider values={values} />,
                    slider
                );
            }
        });
    }

    if (document.querySelectorAll('.big_slideshow').length > 0) {
        const StartpageBigSlider = Loadable({
            loader: () => import('./Components/StartpageBigSlider'),
            loading: () => <div></div>,
        });
        Array.from(document.querySelectorAll('.big_slideshow')).forEach((slider, index) => {
            const values = [...slider.querySelectorAll('.slider__block')].map(block => {
                return ({
                    backgroundimage: block.dataset.backgroundimage,
                    backgroundimagemobile: block.dataset.backgroundimagemobile,
                    text: block.dataset.text,
                    smallimage: block.dataset.smallimage,
                    displayimagetoleft: block.dataset.displayimagetoleft,
                    link: block.dataset.link,
                    slidername: block.dataset.slidername,
                    displaymobileimageontop: block.dataset.displaymobileimageontop,
                    sitename: block.dataset.sitename
                })
            });
            if (values.length > 0) {
                ReactDOM.render(
                    <StartpageBigSlider values={values} />,
                    slider
                );
            }
        });
    }

    if (document.querySelectorAll('.media_slideshow').length > 0) {
        const StartpageBigSlider = Loadable({
            loader: () => import('./Components/MediaSlider'),
            loading: () => <div></div>,
        });
        Array.from(document.querySelectorAll('.media_slideshow')).forEach((slider, index) => {
            const values = [...slider.querySelectorAll('.slider__block')].map(block => {
                return ({
                    text: block.dataset.text,
                    thumbnail: block.dataset.thumbnail,
                    posterimageurl: block.dataset.posterimageurl,
                    mediafile: block.dataset.mediafile,
                    mediacontenttype: block.dataset.mediacontenttype,
                    isvideo: block.dataset.isvideo,
                    sitename: block.dataset.sitename
                })
            });
            if (values.length > 0) {
                ReactDOM.render(
                    <StartpageBigSlider values={values} />,
                    slider
                );
            }
        });
    }

    if (document.getElementById('quickorder')) {
        const QuickOrderContainer = DynamicComponent({
            loader: () => import('./Containers/QuickOrder.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <QuickOrderContainer />
            </Provider>,
            document.getElementById('quickorder')
        );
    }

    var productSheetLink = document.getElementById('product-sheet__Wrapper')
    if(document.getElementById('product-sheet__Wrapper')){
        const values = {
            urlSheet: productSheetLink.dataset.url,
            brand: productSheetLink.dataset.brand,
            articleNumber: productSheetLink.dataset.articlenr,
            urlProductImage: productSheetLink.dataset.urlproductimage,
            name: productSheetLink.dataset.name,
            seoTitle: productSheetLink.dataset.seotitle,
            instructions: productSheetLink.dataset.instructions,
            description: productSheetLink.dataset.description,
            quantity: productSheetLink.dataset.quantity,
            netWeight: productSheetLink.dataset.netweight,
            EAN: productSheetLink.dataset.ean,
            STLength: productSheetLink.dataset.stlength,
            STWidth: productSheetLink.dataset.stwidth,
            STHeight: productSheetLink.dataset.stheight,
            shortInstructions: productSheetLink.dataset.sinstr,
            shortDescription: productSheetLink.dataset.sdesc
        }
        ReactDOM.render(
            <Provider store={store}>
                <ProductSheet values={values} />
            </Provider>,
            document.getElementById('product-sheet__Wrapper')
        )
    }
    
    var companyLinkSelectors = document.querySelectorAll(".company-link-selector")
    if (companyLinkSelectors) {
        companyLinkSelectors.forEach(container => {
            var links = Array.from(container.querySelectorAll("a"))
            ReactDOM.render(
                <Provider store={store}>
                    <CompanyLinkSelector productPage={container.dataset["productPage"] === "true"} links={links.map(anchor => ({ name: anchor.innerText, url: anchor.getAttribute("href") }))} />
                </Provider>,
                container
            );
        })
    }
    
    if (document.getElementById('my-pages-navigation')) {
        const MyPagesNavigation = DynamicComponent({
          loader: () => import('./Components/MyPages/MyPagesNavigation'),
        });
        ReactDOM.render(
          <Provider store={store}>
            <MyPagesNavigation />
          </Provider>,
          document.getElementById('my-pages-navigation')
        );
      }
      if (document.getElementById('my-pages-tab-content')) {
        const MyPagesTabContainer = DynamicComponent({
          loader: () => import('./Components/MyPages/MyPagesTabContainer'),
        });
    
        ReactDOM.render(
          <Provider store={store}>
            <MyPagesTabContainer />
          </Provider>,
          document.getElementById('my-pages-tab-content')
        );
      }
      if (document.getElementById('orderHistoryPage')) {
        const OrderList = DynamicComponent({
          loader: () => import('./Components/OrderHistoryListContainer'),
        });
        ReactDOM.render(
          <Provider store={store}>
            <OrderList />
          </Provider>,
          document.getElementById('orderHistoryPage')
        );
      }
      if (document.getElementById('my-pages-start-page')) {
        const MyPagesStartPage = DynamicComponent({
          loader: () => import('./Components/MyPages/MyPagesStartPage'),
        });  
        ReactDOM.render(
          <Provider store={store}>
            <MyPagesStartPage />
          </Provider>,
          document.getElementById('my-pages-start-page')
        );
      }
}

bootstrapComponents();
//console.log(React.version);