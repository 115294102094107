import React, { Fragment, useEffect, useState } from "react";

const Navigation = ({ links = [], contentLink = null, additionalLinks }) => {
  let menuRef = React.createRef();
  function toggleMenu() {
    menuRef.current.classList.toggle("navbar__menu--show");
  }
  const additionClass =
    contentLink && contentLink.attributes
      ? contentLink.attributes.cssValue
      : null;
  const hasChildrenClass = links.length > 0 ? "has-children" : null;
  const imgExists =
    contentLink && contentLink.imageUrl && contentLink.imageUrl.length > 0;

  function getImgLink() {
    return {
      __html: `<img src=${contentLink.imageUrl} alt=${contentLink.name} title=${
        contentLink.name
      } />`,
    };
  }

  return (
    <Fragment>
      {!contentLink ? (
        <a className="navbar__link--block" onClick={toggleMenu}>
          <i className="fal fa-bars" />
        </a>
      ) : (
        <Fragment>
          <a
            className={`navbar__link ${hasChildrenClass ||
              ""} ${additionClass || ""}`}
            href={contentLink.url || "#"}
            dangerouslySetInnerHTML={
              imgExists ? getImgLink() : { __html: contentLink.name }
            }
          />
          {links.length > 0 && (
            <i
              className="navbar__icon--caret-right navbar__icon navbar__icon--open"
              onClick={toggleMenu}
            />
          )}
        </Fragment>
      )}

      {links.length > 0 && (
        <ul className="navbar__menu" ref={menuRef} test={links.length}>
          <div className="navbar__menu-header">
            {!contentLink ? (
              <span
                className="navbar__icon navbar__icon--close"
                onClick={toggleMenu}
              />
            ) : (
              <Fragment>
                <i
                  className="navbar__icon--caret-left navbar__icon"
                  onClick={toggleMenu}
                />
                <span
                  className="navbar__title"
                  onClick={toggleMenu}
                  dangerouslySetInnerHTML={{ __html: contentLink.name }}
                />
              </Fragment>
            )}
          </div>
          {links.length > 0 &&
            links.map((link, i) =>
              !link.name ? null : (
                <li className="navbar__item" key={`${link.name}-${i}`}>
                  <Navigation links={link.links} contentLink={link} />
                </li>
              )
            )}
          {additionalLinks &&
            additionalLinks.length > 0 &&
            additionalLinks.map((link, i) =>
              !link.text ? null : (
                <li className="navbar__item navbar__item--additional" key={`${link.text}-${i}`}>
                  <a className="navbar__link" href={link.href}>
                    {link.text}
                  </a>
                </li>
              )
            )}
        </ul>
      )}
    </Fragment>
  );
};

export default Navigation;
